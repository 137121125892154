.sectiondqr {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textBtn {
  margin: 5px;
  background-color: #000;
  color: #b68e1d;
  font-size: 12px;
}
.textBtn:hover {
  background-color: #b68e1d;
  color: #000;
}
