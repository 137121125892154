.productcard {
  width: 100%;
  height: auto;
}
.imgContainer {
  padding-top: 60%;
  position: relative;
}
.menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
