.createBtn {
  background-color: #000000;
  color: #b68e1d;
  font-size: medium;
}
.createBtn:hover {
  background-color: #000000;
  color: #b68e1d;
}
.qrBtn {
  background-color: #000000;
  color: #b68e1d;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  transition: box-shadow 0.3s;
  margin-top: 3%; /* Add a smooth transition effect for the box shadow */
  padding: 12px;
}

.qrBtn:hover {
  background-color: #b68e1d;
  color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add a box shadow when hovering */
}
.subBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.saveBtn {
  background-color: #000000;
  color: #b68e1d;
  padding: 10px;
  font-size: 14px;
  margin-right: 3%;
}
.saveBtn:hover {
  background-color: #b68e1d;
  color: #000;
}
.cancelBtn {
  border: 1px solid #000000;
  color: #000000;
  padding: 10px;
  font-size: 14px;
}
.cancelBtn:hover {
  border: 1px solid #b68e1d;
  color: #b68e1d;
}
.sectionqr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}
