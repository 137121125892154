.tableCard {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
}
.tableTitle {
  text-align: center;
  color: #000;
  font-weight: 800;
  font-size: 14px;
}
.viewContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.tableandicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.unlock{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/* .tableHead{
  display: flex;
  justify-content: center;
  align-items: center;
} */
.redText {
  color: #ff0000; /* Change this to your desired red color */
}

.greenText {
  color: green; /* Change this to your desired green color */
  
}
