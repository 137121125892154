/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&family=Nunito&family=Poppins:wght@400;500;600&family=Roboto&display=swap');

/* Base styles for all devices */


/* Styles for larger screens */
.hospitalprofile {
  width: 30%;
  height: 30%;
  border-radius: 10px;
  margin-right: 15px;
}

.hospitalname {
  font-size: 26px;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
  color: #000;
  margin-bottom: 10px;
}



.number {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #000;
}

/* .cate {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.chip {
  margin-left: 0 !important;
  background-color: #ffab8d;
} */

.image1 {
  width: 80%;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 20px;
}

.changingimage {
  width: 250px;
  height: 250px;
  border-radius: 20px;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 30px;
}

/* Styles for tablets and larger screens */
@media (max-width: 1023px) {
  .hospitalprofile {
    width: 40%;
    height: auto;
    margin-right: 10px;
  }

  /* .hospitalname {
    font-size: 22px;
  }

  .licence {
    font-size: 12px;
  }

  .number {
    font-size: 14px;
  } */
  .changingimage {
    width: 300px;
    height: 300px;
  }
  .image1 {
    width: 300px;
    height: 300px;
  }
  .imagecover {
    width: 400px;
    height: auto;
  }
}

/* Styles for mobile devices */
@media (max-width: 767px) {
  .hospitalprofile {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }

  
}
