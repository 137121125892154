.css-6d3pg0-MuiTabs-indicator {
  display: none;
}
/* suborder css */
.statusBtn {
  background-color: #000000;
  color: #b68e1d;
  padding: 10px;
  font-size: 14px;
  /* margin-right: 3%; */
  border-radius: 10px;
  cursor: pointer;
}
.statusBtn:hover {
  background-color: #b68e1d;
  color: #000;
}
.subOrderHeading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

/* status modal */
.btnContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.saveBtn {
  background-color: #000000;
  color: #b68e1d;
  padding: 10px;
  font-size: 14px;
  margin-right: 3%;
  border-radius: 10px;
  cursor: pointer;
}
.saveBtn:hover {
  background-color: #b68e1d;
  color: #000;
}
.cancelBtn{
  border: 1px solid #000000;
    color: #000000;
    padding: 10px;
    font-size: 14px;
}
.cancelBtn:hover{
  border: 1px solid #b68e1d;
   color: #b68e1d;
}

.suborderBtn{
  background-color: #000000;
  color: #b68e1d;
  padding: 5px;
  font-size: 14px;
  margin-right: 3%;
  border-radius: 10px;
  cursor: pointer;
}
.suborderBtn:hover {
  background-color: #b68e1d;
  color: #000;
}