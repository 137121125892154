.expBtn {
  background-color: #000;
  color: #b68e1d;
  padding: 10px;
  font-size: 14px;
  /* margin-right: 3%; */
}
.expBtn:hover {
  background-color: #000000;
  color: #b68e1d;
}
.sectionHistory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}
.subSection{
  display: flex;
    justify-content: space-between;
    align-items: center;
}
